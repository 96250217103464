import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path : '/menu',
    name : 'Menu',
    component : () => import('../views/misc/Menu')
  },
  {
    path : '/',
    name : 'Home',
    component : () => import('../views/dashboard/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue')
  },
  {
    path : '/roles',
    name: 'Roles',
    component: () => import('../views/roles/Roles')
  },
  {
    path : '/add-role',
    name: 'AddRole',
    component: () => import('../views/roles/AddRole')
  },
  {
    path : '/departments',
    name : 'Departments',
    component : () => import('../views/departments/Departments')
  },
  {
    path : '/add-department',
    name : 'AddDepartment',
    component : () => import('../views/departments/AddDepartment')
  },
  {
    path : '/view-department/:id',
    name : 'ViewDepartment',
    component : () => import('../views/departments/ViewDepartment')
  },
  {
    path : '/add-task',
    name : 'AddTask',
    component : () => import('../views/tasks/AddTask')
  },
  {
    path : '/tasks',
    name : 'Tasks',
    component : () => import('../views/tasks/Tasks')
  },
  {
    path : '/add-account',
    name : 'AddAccount',
    component : () => import('../views/user-management/AddAccount')
  },
  {
    path : '/accounts',
    name : 'Accounts',
    component : () => import('../views/user-management/Accounts')
  },
  {
    path : '/view-account/:id',
    name : 'ViewAccount',
    component : () => import('../views/user-management/ViewAccount')
  },
  {
    path : '/edit-account/:id',
    name : 'EditAccount',
    component : () => import('../views/user-management/EditAccount')
  },
  {
    path : '/reset-password/:id',
    name : 'ResetPassword',
    component : () => import('../views/user-management/ResetPassword')
  },
  {
    path : '/projects',
    name : 'Projects',
    component : () => import('../views/projects/Projects')
  },
  {
    path : '/add-project',
    name : 'AddProject',
    component : () => import('../views/projects/AddProject')
  },
  {
    path : '/viewProject/:id',
    name : 'ViewProject',
    component : () => import('../views/projects/ViewProject')
  },
  {
    path : '/add-team/:project',
    name: 'AddTeam',
    component : () => import('../views/projects/AddTeam')
  },
  {
    path : '/add-client',
    name : 'AddClient',
    component : () => import('../views/client/AddClient')
  }
  
]

const router = new VueRouter({
  routes
})

export default router
