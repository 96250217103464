<template>
  <v-app>
    <v-app-bar app color="black" dark height="100">
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          height="50"
        />
      </div>

      <v-spacer></v-spacer>
      <h2 class="f-alfa primary--text uppercase mr-10">Pro Track</h2>
      <!-- <v-img
          alt="Vuetify Logo"
          class="shrink"
          contain
          src="@/assets/pro-track.png"
          transition="scale-transition"
          height="50"
        /> -->
      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <v-container class="mt-5" style="padding-bottom: 150px">
        <!-- <router-view></router-view> -->
        <div v-if="USER != null">
          <router-view v-if="USER.user != undefined" />
          <login v-else />
        </div>
        <login v-else />
      </v-container>
      <div class="vertical-text d-none d-md-flex" style="font-size: 10pt">
        Powered By <span class="primary--text" style="margin-top: 5px;"> AXYZ Global</span>
      </div>
      <div
        style="position: fixed; bottom: 20px; right: 10px"
        class="d-none d-md-flex"
      >
        <div style="background-color: #212121;padding: 30px 10px;border-radius: 20px;">
          <v-icon color="primary" class="mt-5" @click="menu">mdi-menu</v-icon> <br>
          <v-icon color="primary" class="mt-5">mdi-cog</v-icon> <br>
          <v-icon color="primary" class="mt-5">mdi-account-circle-outline</v-icon> <br>
          <v-icon color="primary" class="mt-5">mdi-help-circle-outline</v-icon> <br>
          <v-icon color="primary" class="mt-5">mdi-power</v-icon> <br>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Login from "./views/auth/Login";
export default {
  name: "App",

  data: () => ({
    //
  }),
  components: {
    Login,
  },
  computed: {
    ...mapGetters(["USER","PROGRESS"]),
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    menu(){
      this.$router.push('/menu')
    }
  },
};
</script>

<style>
html,
body {
  background: #000 !important;
  height: 100%;
  font-family: 'Blinker', sans-serif !important;
}
.v-application {
  font-family: "Blinker", sans-serif !important;
}
p {
  font-family: "Blinker", sans-serif !important;
}
h1 {
  font-family: "Blinker", sans-serif !important;
}
.display-1 {
  font-family: "Blinker", sans-serif !important;
}
.v-application .display-1 {
  font-family: "Blinker", sans-serif !important;
}
.v-application .display-2 {
  font-family: "Blinker", sans-serif !important;
}
.v-application .display-3 {
  font-family: "Blinker", sans-serif !important;
}
.v-application .display-4 {
  font-family: "Blinker", sans-serif !important;
}
.theme--dark.v-application {
  background: #000;
}
.toast-title {
  font-family: "Blinker" !important;
  font-weight: 700;
}
.toast-message {
  font-family: "Blinker" !important;
}
.f-alfa {
  font-family: "Alfa Slab One", serif !important;
  letter-spacing: 1px;
  font-weight: 500;
}
.row {
  padding: 0px !important;
  margin: 0px !important;
}

.v-text-field__details {
  display: none !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.fw-bold {
  font-weight: bold !important;
}
.vertical-text {
  writing-mode: vertical-rl; /* This property makes text vertical */
  transform: rotate(
    180deg
  ); /* Rotate the text 180 degrees to make it upright */
  position: fixed;
  bottom: 20px;
  left: 20px;
}

@media (min-width: 960px) {
  .container {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media only screen and (max-width: 575.98px) {
  /* Styles for extra small screens */
  .f-alfa{
    font-size: 14pt !important;
  }
}

.v-toolbar__extension{
  height: 5px !important;
}

</style>
