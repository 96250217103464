<template>
    <div>
      <v-container>
        <v-layout row wrap class="d-flex justify-center mt-10">
          <v-flex xs12 md8 lg6 class="card-bg pa-10 border-radius-10">
            <h1 class="primary--text">Login</h1>
            <p class="mb-5">Login with your email & pasword</p>
            <v-text-field
              label="Email"
              single-line
              outlined
              type="email"
              v-model="email"
            ></v-text-field>
            <v-text-field
              label="Password"
              single-line
              outlined
              type="password"
              v-model="password"
              class="mt-5"
            ></v-text-field>
            <div class="d-flex justify-space-between align-center mt-5">
              <v-btn class="primary pl-10 pr-10 black--text" x-large @click="login">Login</v-btn>
              <a>Forgot Password ?</a>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </template>
  
  <script>
  import Axios from 'axios'
  import { BASE_URL } from '../../config';
  export default {
    data() {
      return {
        email: "",
        password: "",
      };
    },
    methods: {
      async login() {
        this.$store.dispatch("SET_PROGRESS", true);
        let url = BASE_URL + "/login";
        let payload = {
          email: this.email,
          password: this.password,
        };
        if (this.email != "" && this.password != "") {
          let { data } = await Axios.post(url, payload);
          if (data.success) {
            this.$toastr.s(
             
              "Your are successfully logged in to Pro Track", "Logged In",
            );
            this.$store.dispatch("SET_USER", data);
            this.$router.push("/");
          } else {
            this.$toastr.e(
              "Please check your email and password","Login Failed",
            );
          }
          this.$store.dispatch("SET_PROGRESS", false);
        } else {
          this.$toastr.e(
            "Please submit your email and password to login","Missing Data",
          );
          this.$store.dispatch("SET_PROGRESS", false);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .card-bg {
    background: #212121;
  }
  .border-radius-5 {
    border-radius: 5px;
  }
  .border-radius-10 {
    border-radius: 10px;
  }
  
  </style>